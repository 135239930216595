.Footer {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  margin-top: 2.5rem;
  padding: 3.5rem 0 2rem 0;
  border-top: 1px solid rgba(234, 236, 240, 1);
  gap: 1rem;
}


.Content {
  display: flex;
  justify-content: center !important;
  gap: 100px;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.Link {
  text-decoration: none;
  position: relative;
}

.Dot {
  position: absolute;
  left: 5.1rem;
  top: 50%;
}

.Text {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.2rem;
  font-weight: 800;
  color: rgb(224, 227, 233);
}

.TextCop {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.2rem;
  font-weight: 800;
  color: rgb(224, 227, 233);
  margin-top: 2px;
  text-align: center;
}

.ContainerText {
  align-self: center;
  margin-top: 10px;
}

a {
  text-decoration: none;
}

.Image {
  display: inline-block;
  width: 15rem;
}

.ImageDivina {
  width: 10rem;
}

@media (max-width: 920px) {
  .Footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .Content {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .Dot {
    position: absolute;
    left: 4rem;
    top: 44%;
  }

  .Text {
    font-size: 1.25rem;
  }

  .Image {
    display: inline-block;
    width: 10rem;
  }
  
  .ImageDivina {
    width: 6rem;
  }
  
}
