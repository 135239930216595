.SocialUl {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  list-style: none;
  padding: 2.4rem 0;
  margin-top: 2rem;
}

.List {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Link {
  text-decoration: none;
}

.Image {
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;
}

