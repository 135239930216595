@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@font-face {
  font-family: 'Stereo';
  src: url('./assets/STEREOFI.TTF');
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Stereo', sans-serif;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  background-image: url('./assets/backDD.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  animation: moveBackground 10s cubic-bezier(0.4, 0.1, 0.6, 0.9) infinite;
  z-index: -1;
}

@keyframes moveBackground {
  0%, 100% { background-position: 0% 0%; }
  50% { background-position: 100% 100%; }
}


@media (min-width: 768px) {
  body {
    padding: 3rem 9.4rem;
  }
}