.contact-form--container {
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  gap: 4rem;
}

.contact-form--info {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.contact-form--info_title {
  font-size: 3.6rem;
  font-weight: 600;
  letter-spacing: -2%;
  color: #101828;
}

.contact-form--info_desc {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.4;
  color: #475467;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2.75rem;
}

.full-name--container {
  display: flex;
  flex-direction: column;
  gap: 2.75rem;
}

.form-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  font-weight: 500;
  font-size: 1.4rem;
  color: rgba(52, 64, 84, 1);
  position: relative;
  width: 100%;
}

.form-input {
  padding: 1rem 1.4rem;
  border: 1px solid rgba(208, 213, 221, 1);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
  line-height: 1.6;
  outline-color: rgba(132, 202, 255, 1);
}

.form-input:active {
  box-shadow: 0px 0px 0px 4px rgba(132, 202, 255, 1);
}

.form-input::placeholder {
  color: #667085;
}

.input-msg {
  font-size: 1rem;
  color: rgba(248, 63, 35, 1);
  font-weight: 500;
  position: absolute;
  bottom: -1.6rem;
  visibility: hidden;
}

.input-msg.visible {
  visibility: visible;
}

.form-input.danger {
  border-color: rgba(248, 63, 35, 1);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FFD3CC;
}

.checkbox-label {
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  color: #667085;
  line-height: 1.5;
}

.form-checkbox--input {
  width: 2em;
  height: 2rem;
  border-radius: 8px;
}

.form-btn {
  border: 1px solid rgba(21, 112, 239, 1);
  background-color: rgba(21, 112, 239, 1);
  color: white;
  padding: 1.2rem 2rem;
  font-size: 1.6rem;
  font-weight: 600;
  border-radius: 8px;
  outline-color: rgba(132, 202, 255, 1);
}

@media (min-width: 768px) {
  .full-name--container {
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
 .contact-form--container {
  width: 60%;
  margin: 4rem auto;
 } 
}